import tips from '@/common/tips'
import axios from '@/common/axios'
import urls from '@/common/urls'

const recorder = {
  stream:null,
  chunks:[],
  recorder:null,
  callback:null,
  meetId:0,
  page:0,
  voiceLength:0,
  isAutoStart:false,
  camera:{
    audio: {
      noiseSuppression: true, // 降噪
      echoCancellation: true // 回音消除
    },
    // video : {
    //   height:300,
    //   width:300,
    //   facingMode: "user" // 前置摄像头
    //   // facingMode: { exact: "environment" } // 后置摄像头
    // }
  },
  sourceType:{type:"audio/mp3"},
  initRecorder: async () => {
    if(!recorder.stream) return;
    recorder.recorder = new MediaRecorder(recorder.stream);
    

    recorder.recorder.ondataavailable = async (e) => {
      recorder.chunks.push(e.data);
    }
  },
  startRecord: async (meetId= 0,page=0) => {
    if(meetId){
      recorder.meetId =  meetId
    }
    recorder.page =  page + 1
    recorder.chunks = []
    return new Promise((resolve)=>{
      if(!recorder.recorder){
        setTimeout(()=>{
          recorder.initRecorder()
          recorder.recorder &&  recorder.recorder.start()
          resolve({status:200,data:{}})
        },500)
        return;
      }
      if(recorder.recorder.state == "recording"){
        recorder.recorder.onstop = async ()=>{
          recorder.chunks = []
          recorder.recorder.start()
          resolve({status:200,data:{}})
        }
        recorder.recorder.stop()
        return;
      }
      recorder.recorder.start()
      resolve({status:200,data:{}})
    })
  },
  stopRecord: async (voiceLength) => {
    recorder.voiceLength = voiceLength
    recorder.callback = () => {}
    return new Promise((resolve)=>{
      // 录制结束执行
      recorder.recorder.onstop = async ()=>{
        let data = await recorder.uploadVoice()
        if(!data){
          resolve({status:500,data:{}})
        }else{
          resolve({status:200,data:{
            localId:data
          }})
        }
      }
      try{
        if(recorder.recorder.state == "recording"){
          recorder.recorder.stop()
        }else{
          resolve({status:500,data:{}})
        }
      }catch(err)
      {
        resolve({status:500,data:{}})
      }
    })
  },
  getVideoName(){
    let fileName = `meet_video_${recorder.meetId}-${recorder.page || Math.random()}-${Date.now()}`
    if(!recorder.meetId){
      fileName =  `meet_video_${Math.random()}_${Date.now()}`
    }
    return `${fileName}`
  },
  upload:async (url)=>{
    return new Promise(resolve=>{
      resolve({status:200,data:{
        serverId:url,
        page:recorder.page ? recorder.page- 1 : 0
      }})
    })
  },
  uploadVoice: async () => {
    return new Promise(async (resolve)=>{
      console.log(recorder.chunks,recorder.chunks[0].size)
      try{
        if(!recorder.chunks[0] || recorder.chunks[0].size < 5*1000){
          resolve("")
          return
        }
      }catch(err){
        resolve("")
        return;
      }
      let blob = new Blob(recorder.chunks,recorder.sourceType);
      let data = new FormData()
      data.append("file",blob)
      data.append("name",recorder.getVideoName())
      let result = await axios.post(urls.uploads.video,data)
      console.log(result)
      let url = result ? result.url || "" : ""
      recorder.chunks = []
      resolve(url)
    })
  },
  playVoice(url){
  },
  stopVoice(url){
  },

  mediaSuccess: async (stream) => {
    recorder.stream = stream;
    recorder.initRecorder()
  },
  mediaError: async (error) => {
    let content = `访问用户媒体设备失败${error.name}, ${error.message}`
    tips.error({text:content})
  },
  init: async (wx,callback) => {
    let mediaSuccess = (stream)=>{
      recorder.mediaSuccess(stream)
      callback(0)
    }
    let mediaError = (error)=>{
      recorder.mediaError(error)
      callback(1)
    }
    if(navigator.mediaDevices && navigator.mediaDevices.getUserMedia){
      navigator.mediaDevices.getUserMedia(recorder.camera).then(mediaSuccess).catch(mediaError);
      return;
    }
    let execFun = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.getUserMedia
    if(execFun){
      execFun(recorder.camera, mediaSuccess, mediaError)
    }else{
      tips.error({text:'当前浏览器不支持访问用户媒体'});
    }
  }
}

export default recorder
