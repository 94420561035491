<template>
  <div class="meeting_chat_container global_full_screen" @click="closeMessage">
    <div class="meeting_chat_room global_meeting_padding">
      <commonScroll :dataList="getChatList()"  ref="chatScroll">
        <template v-slot="{citems,cindex}" >
          <div class="meeting_chat_item" :key="cindex" :style="getColor(citems)">
            <span class="meeting_chat_name" v-if="!citems.type" >{{citems.name}}:</span>
            <span class="meeting_chat_text" >{{citems.content}}</span>
          </div>
        </template>
      </commonScroll>
    </div>
    <div class="meeting_chat_fast_reply global_meeting_padding"  v-if="showMoreMessage" @click.stop="">
      <div class="meeting_chat_fast_reply_ul" >
        <commonScroll :dataList="meetingData.class_info.question" ref="fastReplyScroll">
          <template v-slot="{citems,cindex}" >
            <div class="meeting_chat_fast_reply_li" @click.stop="fastReply(citems.question)">
              <div class="meeting_chat_fast_reply_li_text">
                {{cindex+1}}、{{citems.question}}
              </div>
              <div class="meeting_chat_fast_reply_li_del " v-if="citems.openid" @click.stop="deleteFastReplay(citems.id,cindex)"> 
                <div class="global_container_center" >
                  <el-icon>
                    <Delete />
                  </el-icon>
                </div>
              </div>
            </div>
          </template>
        </commonScroll>
      </div>
      <div class="meeting_chat_fast_reply_button global_container_center" @click.stop="openPopup">
        <div>
          <span  class="meeting_chat_fast_reply_add">
            <el-icon >
              <Close />
            </el-icon>
          </span>
          <span class="meeting_chat_fast_reply_text">添加自定义问题</span>
        </div>
      </div>
    </div>
    <div class="meeting_chat_group">
      <!-- <div class="meeting_chat_content meeting_guest_chat_content" v-if="!meetingData.meet_info.isDoctor">
        <div class="meeting_chat_input">
          <el-input v-model="data.content" placeholder="请选择或输入您的问题"></el-input>
        </div>
        <div class="meeting_chat_message" >
          <el-icon :size="20">
            <ChatDotSquare />
          </el-icon>
        </div>
      </div> -->
      <div class="meeting_chat_content meeting_guest_chat_content" v-if="!meetingData.meet_info.isDoctor">
        <div class="meeting_chat_message" @click.stop="openMessage">
          <el-icon :size="20">
            <ChatDotSquare />
          </el-icon>
          <span class="meeting_chat_message_text">快捷回复</span>
        </div>
      </div>
      <div class="meeting_chat_content" v-else>       
        <customButton class="meeting_course_step" @click="setPrepStep" ><div>上一页</div></customButton>
        <customButton class="meeting_course_voice" size="small" @touchstart.passive="clickStartRecord" @touchend.passive="clickStopRecord" ><div>{{getButtonText()}}</div></customButton>
        <customButton class="meeting_course_step" @click="setNextStep" ><div>{{ meetingData?.isWatch ? "结束" : "提交" }}</div></customButton>
      </div>
    </div>
    <popup @doClose="doClose" @doSure="doSure" :cfg="config.fastReplay" v-show="showPopup" @click.stop="">
      <div class="meeting_fast_replay_container">
        <el-input type="textarea" v-model="fastReplyData.question" placeholer="请输入您的问题"></el-input>
      </div>
    </popup>
    <popup @doClose="doCloseDeleteWin" @doSure="doSureDeleteWin" :cfg="config.delete" v-show="deleteIndex !== ''" @click.stop="">
      <div class="meeting_fast_replay_container">
        确定要删除自定义问题【{{this.meetingData.class_info?.question[deleteIndex]?.question}}】吗？
      </div>
    </popup>
      <popup :cfg="popupCfg" v-if="showEndTip">
          <div class="meeting_course_preview_tip_content">
              <div class="meeting_course_preview_tip_content_text">
                <span>是否确认结束并提交？</span>
                <span>提交后无法再修改</span>
              </div>
              <div class="meeting_course_preview_add_page">
                  <customButton class="meeting_course_preview_help_button"  size="small" @click="goNextStep()">
                      是
                  </customButton>
                  <customButton class="meeting_course_preview_help_button" size="small" @click="this.showEndTip = false">
                      否
                  </customButton>
              </div>
          </div>
      </popup>
  </div>
</template>
<script>
import {ElIcon,ElInput} from 'element-plus';
import { ChatDotSquare,Close,Delete } from '@element-plus/icons'
import customButton  from '@/components/unit/customButton'
import commonScroll  from '@/components/unit/commonScroll'
import popup  from '@/components/unit/popup'
import weixin  from '@/common/weixin'
import recorderH5 from "@/common/recorderH5";
export default {
  name:"meetingChat",
  props:{
    meetingData:{
      type:Object,
      default:()=>{
        return {}
      }
    }
  },
  data(){
    return {
      popupCfg:{
          title:'',
          content:"",
          button:[]
      },
      config:{
        fastReplay:{
          title:"添加自定义问题",
          button:{
            cancel:"取消",
            sure:"确认添加"
          }
        },
        delete:{
          title:"提示",
          button:{
            cancel:"取消",
            sure:"确认"
          }
        }
      },
      
      voiceTime:{
          maxCount:59 * 1000,
          time:null,
          startTime:0,
          count:0,
          isOver:false
      },
      // 0 普通消息 1系统消息 2 自定义颜色消息
      info:this.$store.state.common.info,
      data:{
        content:""
      },
      fastReplyData:{
        question:""
      },
      showPopup:false,
      showMoreMessage:false,
      clickLock:false,
      loading:false,
      showEndTip:false,
      deleteIndex:""
    }
  },
  created(){
    this.initEnter()
    this.$nextTick(()=>{
      this.$refs.chatScroll.initScroll(true)
    })
  },
  components:{
    ChatDotSquare,
    Close,
    Delete,
    ElIcon,
    ElInput,
    commonScroll:commonScroll,
    customButton:customButton,
    popup
  },
  watch:{
    "meetingData.chat_info":{
      handler:function(newData,oldData){
        this.$nextTick(()=>{
          this.$refs.chatScroll.initScroll(true)
        })
      },
      deep:true
    },
    "meetingData.class_info":{
      handler:function(newData,oldData){
        this.$nextTick(()=>{
          setTimeout(()=>{
            this.$refs.fastReplyScroll && this.$refs.fastReplyScroll.initScroll(false,true)
          },10)
        })
      },
      deep:true
    }
  },
  methods:{
    getChatList(){
      let list = this.meetingData.chat_info?.chat || [];
      return list.filter(x=>!(x.voice_length > 0))
    },
    getColor(data){
      return `color:${data.color || "#000"}`
    },
    fastReply(message){
      console.log(message)
      this.data.content = message
      this.sendMessage()
    },
    
    initEnter(){
        var that = this;
        document.onkeydown = function(e) {
            var key = window.event.keyCode;
            if (key == 13) {
              that.sendMessage()
            }
        }
    },
    initVoiceTime(){
        if(this.voiceTime.time){
            clearTimeout(this.voiceTime.time)
            this.voiceTime.time = null;
        }
        this.voiceTime.count = this.$tools.getTime() - this.voiceTime.startTime;
        this.voiceTime.count = this.voiceTime.count - this.voiceTime.count % 1000;
        console.log(this.voiceTime.count)
        if(this.voiceTime.count >= this.voiceTime.maxCount || this.voiceTime.isOver){
          console.log("时间到了结束录制0")
          let count = this.voiceTime.count;
          this.voiceTime.isOver = false;
          this.voiceTime.startTime = 0;
          this.voiceTime.count = 0;
          this.stopRunRecord(count)
          return;
        }
        this.voiceTime.time = setTimeout(()=>{
            this.initVoiceTime();
        },1000)
    },
    isRecord(){
      return this.voiceTime.startTime > 0;
    },
    getButtonText(){
        if(!this.isRecord()) return "长按录音"
        let count = this.voiceTime.maxCount - this.voiceTime.count  
        return `松开结束录音，剩余${this.$tools.getShowTime(count,"ss秒")}`
    },
    clickStartRecord(){
      if(this.isRecord()) return;
      console.log("开始录制")
      this.startRunRecord()
    },
    clickCancelRecord(){
      console.log(111111111)
    },
    clickStopRecord(){
      if(!this.isRecord()) return
      console.log("结束录制")
      // if(this.clickLock) return;
      // this.clickLock = true;
      // setTimeout(()=>{this.clickLock=false},500)
      this.voiceTime.isOver = true;
    },
    recorder() {
      return recorderH5;
    },
    async startRunRecord(){
      this.voiceTime.startTime = this.$tools.getTime();
      await this.recorder().startRecord();
      this.initVoiceTime();
    },
    async stopRunRecord(voiceLength){
      let item = await this.recorder().stopRecord();
      let data = await this.uploadVoice(item.data.localId, voiceLength);
      let chatContent = this.$tools.getChatContent(
        "",
        1,
        data.serverId,
        Math.floor(voiceLength / 1000),
        10086,
        data.localId,
        2
      );
      this.$emit("sendMessage", "meet_chat", chatContent);
    },
    async uploadVoice(localId,voiceLength) {
      this.isUpload = true;
      let result;
      try {
        result = await this.recorder().upload(localId);
        if (result.status !== 200) return "";
        this.isUpload = false;
      } catch (err) {
        this.isUpload = false;
        return "";
      }
      return {
        serverId: result?.data.serverId,
        voiceLength: voiceLength,
        type: 1,
        localId: localId,
      };
    },
    sendMessage(){
      this.closeMessage();
      this.$emit('talk',this.$tools.getChatContent(this.data.content))
      this.data.content = ""
    },
    setNextStep(){
      if(this.meetingData.isWatch){
        this.$router.back()
        // this.$router.push({
        //   path:"/meetDetail",
        //   query:{
        //     id:this.meetingData.meet_info.id
        //   }
        // })
        return;
      }
      if(this.meetingData.is_test){
        this.goNextStep()
      }else{
        // 如果讨论时间少于5秒钟，不允许结束讨论
        let endDiscussTime = this.$tools.getTime(this.meetingData.meet_phase[1]?.phase_time || "");
        let count = this.meetingData.chat_info.chat.filter(x=>x.content_type == 1).slice(0,this.meetingData.class_info.content.length).reduce((count,item)=>count+item.voice_length,0)
        if(this.meetingData.meet_info.min_voice_len && this.meetingData.meet_info.min_voice_len > count){
          this.$tips.error({text:`录制时长需至少${Math.floor((this.meetingData.meet_info.min_voice_len || 0)/60)}分钟方可提交，谢谢！`})
          return;
        }
        let chatList = this.meetingData.chat_info.chat.filter(x=>x.content_type == 1 && x.voice_id && Number(x.voice_page) < 100)
        let voicePage = this.meetingData.class_info.content.length;
        let notRecord = []
        for(let index=1;index<=voicePage;index++){
          let item = chatList.find(x=>x.voice_page==index)
          if(!item) notRecord.push(index)
        }
        if(notRecord.length > 0){
          this.$tips.error({text:`您的${notRecord.map(x=>"第" + x + "页").join(",")}音频上传失败，请检查网络并重新录制`})
          return
        }
        if(!this.meetingData.meet_phase[1] || this.$tools.getTime() < endDiscussTime + 10* 1000) return;
        this.showEndTip = true;
      }
    },
    goNextStep(){
      this.showEndTip = false
      if(this.meetingData.meet_info.phase ==  this.$config.categary.phase.meeting_end || ( this.meetingData.is_test)) {
        this.$tools.setStorage(`${this.meetingData.edit_pref}_TestMeetInfo`,"",sessionStorage)
        this.$router.back()
        return;
      }
      this.$emit("setNextStep",{phase:this.$config.categary.phase.meeting_end})
    },
    setPrepStep(){
      let data = {
          doctor_index:this.meetingData.class_info.content.length - 1,
          live_index:this.meetingData.class_info.content.length - 2
      }
      if(this.meetingData.isWatch){
        this.$emit("setWatchNextStep",this.$config.categary.phase.meeting_course)
        this.$emit("setWatchNextPage",data)
      }else{
        this.$emit("setNextStep",{phase:this.$config.categary.phase.meeting_course})
        this.$emit("sendMessage","meet_next",data)
        
        // this.$emit("setWatchNextStep",this.$config.categary.phase.meeting_course)
        // this.$emit("setWatchNextPage",data)
      }
      console.log(this.meetingData)
    },
    closeMessage(){
      this.showMoreMessage = false;
    },
    openMessage(){
      this.showMoreMessage = !this.showMoreMessage;
    },
    openPopup(){
      this.showPopup = true;
    },
    doClose(){
        this.showPopup = false;
    },  
    doSure(){
      if(this.fastReplyData.question){
        this.doClose()
        this.addFastReplay()
      }
    },
    deleteFastReplay(id,index){
      console.log(index)
      this.deleteIndex = index;
    },
    doCloseDeleteWin(){
      this.deleteIndex = ""
    },
    doSureDeleteWin(){
      if(this.loading) return;
      this.loading = true;
      let item = this.meetingData.class_info.question[this.deleteIndex]
      let url = this.$tools.getURL(this.$urls.meet.fastReply,{id:item.id})
      this.$axios.delete(url).then(res=>{
        this.$tips.success({text:"删除成功"})
        let list = [].concat(this.meetingData.class_info.question);
        list.splice(this.deleteIndex,1)
        this.$emit("resetFastReplay",list)
        setTimeout(()=>{ this.loading = false},1000)
        this.doCloseDeleteWin();
      }).catch(err=>{
        setTimeout(()=>{ this.loading = false},1000)
        this.doCloseDeleteWin();
      })
    },
    addFastReplay(){
      if(this.loading) return;
      this.loading = true;
      // this.meetingData.push(this.fastReplyData.question)
      // this.$refs.fastReplyScroll.updateList(this.list,true)
      // this.fastReplyData.question = ""
      this.fastReplyData.courseware_id = this.meetingData.meet_info.courseware_id;
      this.fastReplyData.openid = this.$store.state.common.signInfo.openid
      this.$axios.post(this.$urls.meet.fastReply,this.fastReplyData).then(res=>{
        this.$tips.success({text:"新增成功"})
        this.fastReplyData.id = res.data;
        this.$emit("resetFastReplay",[].concat({...this.fastReplyData},this.meetingData.class_info.question))
        this.fastReplyData.question = ""
        setTimeout(()=>{ this.loading = false},1000)
      }).catch(err=>{
        // this.$tips.error({text:"添加失败了哟"})
        setTimeout(()=>{ this.loading = false},1000)
      })
    }
  }
}
</script>
<style>
.meeting_chat_container{
  position: relative;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
}
.meeting_chat_room{
  flex:1 0 0;
  width:100%;
  padding-top:4vw;
  padding-bottom:4vw;
  overflow: hidden;
}
.meeting_chat_item{
  width:100%;
  /* display: flex; */
  line-height: 4.8vw;
  font-size:3.6vw;
  margin:1vw 0;
}
.meeting_chat_name{
  color:#f1812f;
  white-space: nowrap;
  margin-right:2vw;
}
.meeting_chat_text{
  /* color:#f1812f; */
}
.meeting_chat_group{
  flex:12vw 0 0;
  background:#fff;
  width:100%;
  padding:0 3.2vw;
  display: flex;
  align-items: center;
  position: relative;
}
.meeting_chat_content{
  width:100%;
  height:8.56vw;
  display: flex;
  align-items: center;
  position: relative;
}
.meeting_course_voice{
  height:100%;
  width:100%;
  margin:0 4vw;
}
.meeting_course_step{
  width:21.32vw;
}
.meeting_guest_chat_content{
  border:0.13vw solid #e6e6e6;
  padding:0 3.2vw;
  display: flex;
  justify-content: center;
}
.meeting_chat_input{
  flex:1 0 0;
}
.meeting_chat_message{
  flex:5.6vw 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  /* margin-left:3.2vw; */
}
.meeting_chat_message_text{
  margin-left:1vw;
}
.meeting_chat_container .el-input,.meeting_chat_container input{
  height:8vw;
  line-height: 8vw;
  font-size:3.6vw;
}
.meeting_chat_container input{
  border:none;
  padding:0;
  margin:0;
}
.meeting_chat_fast_reply{
  height:50vw;
  width:100%;
  position: absolute;
  bottom:14vw;
  left:0;
  z-index:101;
  padding-top:3vw;
  background:#fff;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.meeting_chat_fast_reply_ul{
  flex:1 0 0;
  width:100%;
  overflow: hidden;
}
.meeting_chat_fast_reply_li{
  width:100%;
  font-size:3.6vw;
  line-height: 5.6vw;
  display: flex;
}
.meeting_chat_fast_reply_li_text{
  flex:1 0 0;
  word-break: break-all;
}
.meeting_chat_fast_reply_li_del{
  flex:8vw 0 0;
  display: flex;
  justify-content: center;
}
.meeting_chat_fast_reply_li_del>div{
  height:5.6vw;
}
.meeting_chat_fast_reply_button{
  flex:7vw 0 0;
  padding:1vw 0;
  color:#0000c9;
  font-size:3.6vw;
}
.meeting_chat_fast_reply_add{
  display: inline-block;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  margin-right:1vw;
  font-weight: 700;
}
.meeting_chat_fast_reply_text{}
.meeting_chat_fast_reply_button div{
  display: flex;
  align-items:center;
  justify-content: center;
}
.meeting_fast_replay_container{
  width:80%;
  min-height:18vw;
  word-break: break-all;
  display: flex;
  align-items: center;
}
.meeting_fast_replay_container textarea{
  height:16vw;
  font-size:3.2vw;
}
.meeting_course_preview_tip_content_text{
  display: flex;
  flex-direction: column;
  line-height:4.8vw;
}
</style>